// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-live-entry-tsx": () => import("/opt/build/repo/src/templates/live-entry.tsx" /* webpackChunkName: "component---src-templates-live-entry-tsx" */),
  "component---src-pages-biography-index-tsx": () => import("/opt/build/repo/src/pages/biography/index.tsx" /* webpackChunkName: "component---src-pages-biography-index-tsx" */),
  "component---src-pages-discography-index-tsx": () => import("/opt/build/repo/src/pages/discography/index.tsx" /* webpackChunkName: "component---src-pages-discography-index-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-history-index-tsx": () => import("/opt/build/repo/src/pages/live/history/index.tsx" /* webpackChunkName: "component---src-pages-live-history-index-tsx" */),
  "component---src-pages-live-index-tsx": () => import("/opt/build/repo/src/pages/live/index.tsx" /* webpackChunkName: "component---src-pages-live-index-tsx" */),
  "component---src-pages-movie-index-tsx": () => import("/opt/build/repo/src/pages/movie/index.tsx" /* webpackChunkName: "component---src-pages-movie-index-tsx" */)
}

